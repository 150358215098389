<template>
  <div>
    <div class="_SelectHeader" style="justify-content: space-between">
      <div>
        <span style="margin-left: 15px; width: 44px"> 区域：</span>
        <el-select v-model="areaId" clearable placeholder="请选择">
          <el-option
            v-for="item in areaList"
            :key="item.value"
            :label="item.areaName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span style="margin-left: 15px; width: 44px">级别：</span>
        <el-select v-model="level" clearable placeholder="请选择">
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" @click="getTableData">搜索</el-button>
      </div>
      <div>
        <el-tooltip
          class="item"
          effect="dark"
          content="导入文件格式为xlsx"
          placement="top"
        >
          <el-button class="_ImgBtn" type="primary" @click="uploadFile">
            <img
              src="../../assets/images/import2.png"
              style="margin-right: 5px"
            />
            导入
          </el-button>
        </el-tooltip>
        <input
          type="file"
          @change="importFile(this)"
          id="imFile"
          ref="fileBtn"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <!-- <el-button
          class="_ImgBtn"
          type="primary"
          style="margin-left: 10px"
          @click="exportExcel"
        >
          <img
            src="../../assets/images/import1.png"
            style="margin-right: 5px"
          />
          导出
        </el-button> -->
        <el-button class="_ImgBtn" type="primary" @click="addQuestion">
          <img src="../../assets/images/add2.png" />
          添加题库</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="tableList"
        :height="elementHeight"
        style="width: 100%"
        border
        id="table"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="standerNo" label="标准编号"></el-table-column>
        <el-table-column prop="standerName" label="标准名称"></el-table-column>
        <el-table-column prop="remark" label="条款"></el-table-column>
        <el-table-column prop="areaName" label="区域"></el-table-column>
        <el-table-column prop="content" label="检查内容"> </el-table-column>
        <el-table-column prop="auditModule" label="审核方式"></el-table-column>
        <el-table-column prop="answer" label="标准要求"> </el-table-column>
        <el-table-column prop="version" label="版本号"> </el-table-column>
        <el-table-column prop="level" label="级别"> </el-table-column>
        <el-table-column prop="soceS" label="单项分值（额定总分）">
        </el-table-column>
        <el-table-column prop="soceRule" label="扣分规则"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-delete" @click="deleteItem(scope.row.id)"></i>
            <i class="el-icon-edit" @click="editItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog
      title="添加题库"
      width="50%"
      top="15vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="showDialog = false"
    >
      <div class="contaion">
        <el-form ref="form" label-width="100px">
          <el-form-item label="标准编号：">
            <el-input placeholder="请输入标准编号" v-model="form.standerNo">
            </el-input>
          </el-form-item>
          <el-form-item label="标准名称：">
            <el-input placeholder="请输入标准名称" v-model="form.standerName">
            </el-input>
          </el-form-item>
          <el-form-item label="条款：">
            <el-input placeholder="请输入条款" v-model="form.remark">
            </el-input>
          </el-form-item>
          <el-form-item label="区域：">
            <el-select v-model="form.areaId" placeholder="请选择区域">
              <el-option
                v-for="item in areaList"
                :key="item.value"
                :label="item.areaName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="检查内容：">
            <el-input placeholder="请输入检查内容" v-model="form.content">
            </el-input>
          </el-form-item>
          <el-form-item label="审核方式：">
            <el-input placeholder="请输入审核方式" v-model="form.auditModule">
            </el-input>
          </el-form-item>
        </el-form>
        <el-form ref="form" label-width="100px">
          <el-form-item label="标准要求：">
            <el-input placeholder="请输入标准要求" v-model="form.answer">
            </el-input>
          </el-form-item>
          <el-form-item label="版本号：">
            <el-input placeholder="请输入版本号" v-model="form.version">
            </el-input>
          </el-form-item>
          <el-form-item label="级别：">
            <el-select v-model="form.level" clearable placeholder="请选择">
              <el-option
                v-for="item in levelList"
                :key="item.value"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单项分值：">
            <el-input
              placeholder="请输入单项分值（额定总分）"
              v-model="form.soceS"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="扣分规则：">
            <el-input placeholder="请输入扣分规则" v-model="form.soceRule">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
import { exportExcel } from "../../modules/ExportExcel";
import XLSX from "xlsx";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      areaList: [],
      levelList: [],
      areaId: null,
      level: "",
      tableList: [],
      elementHeight: 0,
      //添加题库
      showDialog: false,
      form: {},
    };
  },
  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
    this.getAreaList();
    this.getLevelList();
    this.getTableData();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 104);
      });
    },
    getAreaList() {
      get("/api/Area/GetList").then((resp) => {
        if (resp.code == 200) {
          this.areaList = resp.data;
        }
      });
    },
    getLevelList() {
      get("/api/Tb5SQuestionPool/GetLevelList").then((resp) => {
        if (resp.code == 200) {
          this.levelList = resp.data;
        }
      });
    },
    getTableData() {
      var url1 = "/api/Tb5SQuestionPool/GetAByAreaId?areaId=" + this.areaId;
      var url2 = "/api/Tb5SQuestionPool/GetAByLevel?level=" + this.level;
      var url3 =
        "/api/Tb5SQuestionPool/GetAByAreaAndLevel?areaId=" +
        this.areaId +
        "&level=" +
        this.level;
      var url = "";
      if (this.areaId && this.level) {
        url = url3;
      } else if (this.areaId) {
        url = url1;
      } else if (this.level) {
        url = url2;
      } else {
        //获取所有
        url = "/api/Tb5SQuestionPool/GetAList";
      }
      get(url).then((resp) => {
        if (resp.code == 200) {
          this.tableList = resp.data;
        }
      });
    },
    // 添加修改删除
    addQuestion() {
      this.isAdd = true;
      this.showDialog = true;
      this.form = {
        saveTime: new Date().toISOString(),
        saverUserId: this.$store.state.userInfo.id,
      };
    },
    handleReqData() {
      var areaItem = this.areaList.filter((i) => {
        return i.id == this.form.areaId;
      });
      this.form.areaName = areaItem[0].areaName;
      this.form.standerNo = this.form.standerNo + "";
      this.form.version = this.form.version + "";
      this.form.soceS = Number(this.form.soceS);
      this.form.soceRule = this.form.soceRule + "";
      var method = this.isAdd ? post : put;
      method(
        "/api/Tb5SQuestionPool" + (this.isAdd ? "?id=" + this.form.id : ""),
        this.form
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "添加成功" : "修改成功",
            });
            this.showDialog = false;
            this.getTableData();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "添加失败" : "修改失败");
        });
    },
    editItem(row) {
      this.isAdd = false;
      this.showDialog = true;
      this.form = row;
    },
    deleteItem(id) {
      this.$confirm("您确认要删除当前所选中的列表？").then(() => {
        deleteMethod("/api/Tb5SQuestionPool?id=" + id)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableData();
              this.tableRowIndex = null;
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("删除失败");
          });
      });
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.tableList = [
            {
              standerNo: "XCGL-001",
              standerName: "5S现场管理体系",
              content: "是否制定了5S管理组织架构",
              auditModule: "抽查相关的书面证据",
              answer: "制定了5S管理组织架构",
              version: "LV0.1",
              remark: "5S管理组织架构及责任管理",
              level: "string",
              soceS: "4",
              soceRule: "不符扣4分",
              areaName: "办公区域",
            },
          ];
          if (this.tableList.length != 0) {
            this.$nextTick(() => {
              exportExcel("#table", "题库导入.xlsx");
              this.tableList = [];
            });
          }
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      this.tableList = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        excelData.map((item, index) => {
          console.log(item);
          if (!Object.prototype.hasOwnProperty.call(item, "标准编号")) {
            item.标准编号 = excelData[index - 1].标准编号;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "标准名称")) {
            item.标准名称 = excelData[index - 1].标准名称;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "条款")) {
            item.条款 = excelData[index - 1].条款;
          }
          var data = {
            standerNo: item.标准编号,
            standerName: item.标准名称,
            content: item.检查内容,
            auditModule: item.审核方式,
            answer: item.标准要求,
            version: item.版本号,
            remark: item.条款,
            level: item.级别,
            soceS: item["单项分值（额定总分）"],
            soceRule: item.扣分规则,
            areaName: item.区域,
            saverUserId: $this.$store.state.userInfo.id * 1,
            saveTime: new Date().toISOString(),
          };
          $this.tableList.push(data);
        });
        if ($this.tableList.length != 0) {
          $this.importData($this.tableList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null;
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    async importData(tableList) {
      this.loading = true;
      await post("/api/Tb5SQuestionPool/PostBatch", tableList)
        .then((resp) => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "导入成功",
          });
          this.getTableData();
          if (resp.code != 200) {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
  },
};
</script>

<style>
.dialog .contaion {
  display: flex;
  justify-content: space-around;
  padding-right: 20px;
}
.el-icon-delete {
  color: red;
  font-size: 15px;
}
.el-icon-edit {
  color: rgb(217, 97, 54);
  font-size: 15px;
  margin-left: 10px;
}
</style>